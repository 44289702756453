<template>
  <div>
    <HeaderContent label="Settings" :list="crumbs" />

    <div class="filter__card">
      <div style="width: 200px">
        <k-input
            :disabled="false"
            placeholder="key"
            v-model="keyModel"
            @blur="inputKey"
        ></k-input>
      </div>
      <custom-button
          color="kellygreen"
          class="white--text"
          size="x-medium"
          @click="searchCommonConfigList"
      >
        Query
      </custom-button>
      <custom-button
          color="red"
          class="white--text"
          size="x-medium"
          @click="handleNew"
      >
        Buat config
      </custom-button>
    </div>

    <v-data-table
        :headers="headers"
        :items="items"
        hide-default-footer
        disable-filtering
        disable-sort
        :loading="loading"
        loading-text="Mohon Tunggu Beberapa Saat"
        no-data-text="Tidak ada data konfigurasi"
    >

      <template v-slot:[`item.value`]="{ item }">
        <v-textarea
            v-model="item.value"
            :readonly="!item.isEditing"
            outlined
            dense
            auto-grow
            :rows="1"
        :style="{
        width: '800px',
        maxHeight: '120px',
        overflowY: 'auto',
        lineHeight: '24px',
        paddingTop: '6px',
        paddingBottom: '6px'
        }"
        />
      </template>
      <template v-slot:[`item.createAt`]="{ item }">
        <span>{{ formatDateTime(item.createAt) }}</span>
      </template>
      <template v-slot:[`item.modifyAt`]="{ item }">
        <span>{{ formatDateTime(item.modifyAt) }}</span>
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <v-btn icon @click="handleEdit(item)">
          <v-icon x-small color="primary">$edit</v-icon>
        </v-btn>
        <v-btn icon @click="handleDelete(item)">
          <v-icon x-small color="grey">$delete</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-pagination
        class="d-flex justify-end"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
        v-model="pageNum"
        :length="totalPages"
        @input="getCommonConfigAll"
        :total-visible="6"
    ></v-pagination>

    <!-- 确认删除的弹框 -->
    <v-dialog v-model="deleteDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Konfirmasi penghapusan</v-card-title>
        <v-card-text>Apakah anda yakin ingin menghapus catatan ini? Operasi ini tidak bisa dibatalkan.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="cancelDelete">batalkan</v-btn>
          <v-btn color="error" text @click="performDelete">konfirmasi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 删除成功的提示对话框 -->
    <v-dialog v-model="successDeleteDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Sukses dihapus</v-card-title>
        <v-card-text>Rekaman berhasil dihapus.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeSuccessDeleteDialog">yakin</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 删除失败的提示对话框 -->
    <v-dialog v-model="errorDeleteDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Gagal untuk menghapus</v-card-title>
        <v-card-text>Gagal menghapus silahkan coba lagi nanti.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeErrorDeleteDialog">yakin</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 编辑弹框 -->
    <v-dialog v-model="editDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Sunting data</v-card-title>
        <v-card-text>
          <v-form>
            <!-- key字段，设置为只读 -->
            <v-text-field
                label="Key"
                v-model="itemToEdit.key"
                readonly
            ></v-text-field>

            <!-- value字段，可编辑，支持 JSON 或普通文本格式 -->
            <!--:rules="[validateJson]"
                hint="支持 JSON 格式或普通文本"-->
            <v-textarea
                label="Value"
                v-model="itemToEdit.value"
                rows="5"
                persistent-hint
            ></v-textarea>

            <v-textarea
                label="Description"
                v-model="itemToEdit.description"
                rows="2"
                persistent-hint
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeEditDialog">batalkan</v-btn>
          <v-btn color="blue darken-1" text @click="saveChanges">simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 编辑成功的提示对话框 -->
    <v-dialog v-model="successEditDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Revisi berhasil</v-card-title>
        <v-card-text>Catatan telah diubah.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeSuccessEditDialog">yakin</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 编辑失败的提示对话框 -->
    <v-dialog v-model="errorEditDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Revisi gagal</v-card-title>
        <v-card-text>Revisi gagal. Silahkan coba lagi nanti.</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeErrorEditDialog">yakin</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 新增弹框 -->
    <v-dialog v-model="newDialog" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Data baru</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
                label="Key"
                v-model="itemToNew.key"
            ></v-text-field>

            <v-textarea
                label="Value"
                v-model="itemToNew.value"
                rows="5"
                persistent-hint
            ></v-textarea>
            <v-textarea
                label="Description"
                v-model="itemToNew.description"
                rows="2"
                persistent-hint
            ></v-textarea>
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeNewDialog">batalkan</v-btn>
          <v-btn color="blue darken-1" text @click="newData">simpan</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 新增成功的提示对话框 -->
    <v-dialog v-model="successNewDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Tambahkan sukses</v-card-title>
        <v-card-text>Tambahkan sukses</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeSuccessNewDialog">yakin</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 新增失败的提示对话框 -->
    <v-dialog v-model="errorNewDialog" max-width="400px">
      <v-card>
        <v-card-title class="headline">Tambahkan gagal</v-card-title>
        <v-card-text>{{ errorMessage }}</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="closeErrorNewDialog">yakin</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import HeaderContent from "@/containers/HeaderContent";
import {mapActions} from "vuex";

export default {
  components: {
    HeaderContent
  },
  data() {
    return {
      crumbs: [
        {
          text: "common config",
          disabled: true,
        },
      ],
      headers: [
        {
          text: "key",
          value: "key",
          class: "whitesnow",
          width: "120",
        },
        {
          text: "value",
          value: "value",
          class: "whitesnow",
          width: "120",
        },
        {
          text: "description",
          value: "description",
          class: "whitesnow",
          width: "120",
        },
        {
          text: "create time",
          value: "createAt",
          class: "whitesnow",
          width: "120"
        },
        {
          text: "Modify time",
          value: "modifyAt",
          class: "whitesnow",
          width: "120"
        },
        {
          value: "action",
          class: "whitesnow",
          width: "100",
        },
      ],
      items: [],
      loading: false,
      payload: {
        key: "",
        size: 10,
        page: 1,
      },
      pageNum: 1,
      totalPages: 0,
      keyModel: "",
      /*删除*/
      deleteDialog: false,
      itemToDelete: null,
      successDeleteDialog: false,
      errorDeleteDialog: false,
      /*编辑*/
      itemToEdit: {
        key: '',
        value: '',
        description: '',
      },
      editDialog: false,
      successEditDialog: false,
      errorEditDialog: false,
      /*新增*/
      newDialog: false,
      itemToNew: {
        key: '',
        value: '',
        description: '',
      },
      successNewDialog: false,
      errorNewDialog: false,

      errorMessage: '',
    }
  },
  created() {
    this.getCommonConfigAll();
  },
  methods : {
    ...mapActions({
      fetchCommonConfigAll: "commonConfig/getCommonConfigAll",
      fetchCommonConfigByKey: "commonConfig/getCommonConfigByKey",
      deleteCommonConfigById: "commonConfig/deleteCommonConfig",
      editCommonConfig: "commonConfig/editCommonConfig",
      addCommonConfig: "commonConfig/saveConfig"
    }),
    async getCommonConfigAll(p){
      this.payload.page = p || 1;
      const response = await this.fetchCommonConfigAll(this.payload);
      if(response.status === 200){
        this.items = response.data.data.content;
        this.totalPages = Math.ceil(response.data.data.totalElements / this.payload.size);
      }
    },
    async getCommonConfigByKey(){
      const response = await this.fetchCommonConfigByKey(this.payload);
      if(response.status === 200){
        this.items = response.data.data.content;
        this.totalPages = Math.ceil(response.data.data.totalElements / this.payload.size);
      }
    },
    formatDateTime(time) {
      const date = new Date(time);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    searchCommonConfigList(){
      this.payload.size = 10;
      this.payload.page = 1;
      if(this.payload.key != null && this.payload.key !== "" ){
        this.getCommonConfigByKey();
      }else {
        this.getCommonConfigAll();
      }
      this.pageNum = 1;
    },
    inputKey(){
      this.payload.key = this.keyModel;
    },
    /*删除记录函数*/
    handleDelete(item){
      this.deleteDialog = true;
      this.itemToDelete = item;
    },
    cancelDelete() {
      this.deleteDialog = false;
      this.itemToDelete = null;
    },
    async performDelete() {
      if (this.itemToDelete) {
        const response = await this.deleteCommonConfigById(this.itemToDelete);
        if(response.status === 200){
          this.successDeleteDialog = true;
          this.getCommonConfigAll();
          this.pageNum = 1;
        }else {
          this.errorDeleteDialog = true;
        }
      }
      this.deleteDialog = false;
      this.itemToDelete = null;
    },
    closeSuccessDeleteDialog(){
      this.successDeleteDialog = false;
    },
    closeErrorDeleteDialog(){
      this.errorDeleteDialog = false;
    },
    /*编辑记录函数*/
    handleEdit(item){
      this.editDialog = true;
      this.itemToEdit.key = item.key;
      this.itemToEdit.value = item.value;
      this.itemToEdit.description = item.description;
    },
    closeEditDialog(){
      this.editDialog = false;
      this.itemToEdit.key = '';
      this.itemToEdit.value = '';
    },
    async saveChanges(){
      if (this.itemToEdit) {
        const response = await this.editCommonConfig(this.itemToEdit);
        if(response.status === 200){
          this.getCommonConfigAll();
          this.pageNum = 1;
          this.successEditDialog = true;
        }else {
          this.errorEditDialog = true;
        }
      }
      this.editDialog = false;
      this.itemToEdit.key = '';
      this.itemToEdit.value = '';
    },
    closeSuccessEditDialog(){
      this.successEditDialog = false;
    },
    closeErrorEditDialog(){
      this.errorEditDialog = false;
    },
    /*新增记录函数*/
    handleNew(){
      this.newDialog = true;
    },
    closeNewDialog(){
      this.newDialog = false;
      this.itemToNew.key = '';
      this.itemToNew.value = '';
      this.itemToNew.description = '';
    },
    async newData(){
      if (this.itemToNew) {
        const response = await this.addCommonConfig(this.itemToNew);
        if(response.status === 200){
          this.getCommonConfigAll();
          this.pageNum = 1;
          this.successNewDialog = true;
        }else {
          this.errorMessage = response.response.data.data;
          this.errorNewDialog = true;
        }
      }
      this.newDialog = false;
      this.itemToNew.key = '';
      this.itemToNew.value = '';
      this.itemToNew.description = '';
    },
    closeSuccessNewDialog(){
      this.successNewDialog = false;
    },
    closeErrorNewDialog(){
      this.errorNewDialog = false;
    },
    /*校验文本是否为json格式*/
    validateJson(value) {
      try {
        if (value && typeof value === 'string') {
          JSON.parse(value);
        }
        return true;
      } catch (e) {
        return '请输入有效的 JSON 格式或普通文本';
      }
    },
  }
}
</script>